import { Typography } from '@mui/material'
import { Loading } from './Loading'
import * as React from 'react'
import { Fragment } from 'react'
import Title from './Title'
import { Pane } from './ReactUtils'

export function AccessDenied() {
  return <div className={'singleMessagePanel'}>
    <Typography textAlign="center">Access Denied.</Typography>
  </div>
}

export function WhenReady({ loading, error, data, showEmpty, ...props }) {
  if (error) {
    if (error.code === 'permission-denied') {
      console.log('--------->', error)
      return <AccessDenied/>
    } else {
      console.error(error)
      return <div className={'singleMessagePanel'}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    }
  }

  if (loading) {
    return <Loading/>
  }

  if (showEmpty && !(data && data.docs && data.docs.length)) {
    return <Pane><Title>Emtpy dataset</Title></Pane>
  }

  return <Fragment>{props.children}</Fragment>
}

import * as React from 'react'
import { Fragment, useState } from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import Title from './Title'
import { useCollection } from 'react-firebase-hooks/firestore'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { auth, firebaseApp, firestore } from './firebase'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined'
import { WhenReady } from './AccessDenied'
import { ButtonWithQuestion, MenuItemWithConfirmation, Pane, RowIconMenu } from './ReactUtils'
import AddIcon from '@mui/icons-material/Add'
import { UnknownUsers } from './UnknownUsers'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

function UsersContainer() {
  const [accessRightsOpen, setAccessRightsOpen] = useState(false)
  const [selectedRef, setSelectedRef] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [value, loading, error] = useCollection(
    query(collection(getFirestore(firebaseApp), 'users')), { snapshotListenOptions: { includeMetadataChanges: true } }
  )

  function openAccessRights(ref, row) {
    setSelectedRef(ref)
    setSelectedRow(row)
    setTimeout(() => setAccessRightsOpen(true))
  }

  function closeAccessRights() {
    setAccessRightsOpen(false)
  }

  async function saveAccessRights({ accessRights, dashboard }) {
    await updateDoc(selectedRef, { accessRights, dashboard })
  }

  return <WhenReady loading={loading} error={error} data={value}>
    <Pane>
      <Title>Active Users</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Since</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {value && value.docs.map((user) => {
            const row = user.data()
            const { id: email, ref } = user
            return (
              <TableRow key={email}>
                <TableCell>{email}</TableCell>
                <TableCell>{row.started && row.started.toDate().toISOString()}</TableCell>
                <TableCell>

                  <RowIconMenu>
                    <MenuItemWithConfirmation
                      disabled={email === auth.currentUser.email}
                      title="Remove user?"
                      content={
                        <span>By confirming this, the user <b>{email}</b> will no longer have access to the system.</span>}
                      onConfirm={async () => {
                        await deleteDoc(doc(firestore, 'users', email))
                      }}>
                      <PersonRemoveIcon/>
                    </MenuItemWithConfirmation>

                    <MenuItem onClick={() => openAccessRights(ref, row)}>
                      <Box flexDirection={'row'} display={'flex'} gap={'1em'}>
                        <EditOutlinedIcon/> <Typography>Edit Access Rights</Typography>
                      </Box>
                    </MenuItem>

                    {!row.admin ?
                      <MenuItemWithConfirmation
                        title="Make admin?"
                        content={
                          <span>By confirming this, the user <b>{email}</b> will have admin powers in the system.</span>}
                        onConfirm={async () => {
                          await setDoc(doc(firestore, 'users', email), { admin: true }, { merge: true })
                        }}>
                        <GroupAddOutlinedIcon/> <Typography>Make admin</Typography>
                      </MenuItemWithConfirmation> :
                      <MenuItemWithConfirmation
                        title="Remove admin powers?"
                        content={
                          <span>By confirming this, the user <b>{email}</b> will lose their admin powers in the system.</span>}
                        onConfirm={async () => {
                          await setDoc(doc(firestore, 'users', email), { admin: false }, { merge: true })
                        }}>
                        <GroupRemoveOutlinedIcon/> <Typography>Remove admin powers</Typography>
                      </MenuItemWithConfirmation>
                    }

                  </RowIconMenu>

                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <ButtonWithQuestion
        title="Authorize User"
        tooltip="Add user"
        content={
          <span>Whats the email address they are going to use in the system?</span>}
        label="Email"
        type="email"
        onConfirm={async (email) => {
          setDoc(doc(firestore, 'users', email), { started: serverTimestamp() })
          const unknownUsersRef = doc(firestore, 'unknownUsers', email)
          getDoc(unknownUsersRef).then(data => {
            if (data.exists()) {
              deleteDoc(unknownUsersRef)
            }
          })
        }}>
        <AddIcon/>
      </ButtonWithQuestion>
    </Pane>
    {accessRightsOpen && <AccessRightsDialog open={accessRightsOpen} onConfirm={saveAccessRights}
                                             handleClose={closeAccessRights}
                                             initialAccessRights={selectedRow && selectedRow.accessRights || ''}
                                             initialDashboard={selectedRow && selectedRow.dashboard || ''}
    />}

  </WhenReady>

}

export function Users() {
  return <Fragment>
    <UsersContainer/>
    <UnknownUsers/>
  </Fragment>
}

function AccessRightsDialog({
                              open,
                              onConfirm,
                              initialAccessRights,
                              initialDashboard,
                              handleClose,
                              pcr = false
                            }) {
  const [accessRights, setAccessRights] = useState(initialAccessRights)
  const [dashboard, setDashboard] = useState(initialDashboard)

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Access Rights</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '1em', padding: '1em' }}>
        <TextField
          label="Dashboard"
          sx={{ flex: 1 }}
          value={dashboard}
          onChange={(e) => setDashboard(e.target.value)}
        />

        <TextField
          multiline={true}
          rows={10}
          label="Access Rights"
          sx={{ flex: 1 }}
          value={accessRights}
          onChange={(e) => setAccessRights(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => {
          handleClose()
          onConfirm({ accessRights, dashboard })
        }}>Save</Button>

      </DialogActions>
    </Dialog>

  )
}

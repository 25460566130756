/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { Pane } from './ReactUtils'
import { Box, FormControl, Tab, Tabs, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { firestore } from './firebase'
import { collection, doc } from 'firebase/firestore'
import { WhenReady } from './AccessDenied'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid'
import { friendlyNameTransform } from 'nemean-server/src/util/Transforms'


export function EditUpload() {
  const { id } = useParams()

  const uploadRef = doc(firestore, 'imports', id)

  const [upload, loading, error] = useDocument(
    uploadRef, { snapshotListenOptions: { includeMetadataChanges: true } })

  const data = upload?.data()
  return <Fragment>
    <WhenReady loading={loading} error={error} data={upload}>
      <Pane style={{ gap: '1em', display: 'flex', flexDirection: 'column' }}>
        <FormControl style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em' }}>
          <Field data={data?.timestamp.toDate().toLocaleString()} label="Timestamp"/>
          <Field data={data?.by} label="By"/>
          <Field style={{ minWidth: '300px', flexGrow: 1 }}
                 data={`${data?.status}${data?.reason ? ' - ' + data?.reason : ''}`} label="Status"/>
        </FormControl>

        <Companies uploadRef={uploadRef}/>
      </Pane>
    </WhenReady>
  </Fragment>
}

export function Companies({ uploadRef }) {
  const [company, setCompany] = React.useState(null)

  const companiesRef = collection(uploadRef, 'companies')

  const [companies, loading, error] = useCollection(companiesRef)

  console.log('-------> 0:', companiesRef)
  console.log('-------> 1:', companies)
  console.log('-------> 2:', companies?.docs)

  return <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={company}
      onChange={(event, newValue) => setCompany(newValue)}
      sx={{ borderRight: 1, borderColor: 'divider' }}
    >
      {companies?.docs.map((doc) => {
        const data = doc.data()
        return <Tab value={doc.id} id={doc.id} label={doc.id} key={doc.id}/>
      })}
    </Tabs>
    {companies?.docs.map((doc, i) => {
      if (doc.id !== company) {
        return <div key={doc.id}/>
      }
      return <GroupsTab companiesRef={companiesRef} value={doc.id} key={doc.id} index={doc.id}/>
    })}
  </Box>
}

function Field({ data, label, ...props }) {
  return <TextField {...props} InputLabelProps={{ shrink: true }} label={label} value={data || ''}/>
}

function GroupsTab({ companiesRef, ...props }) {
  const { children, value, index, ...other } = props
  const companyRef = doc(companiesRef, value)
  const [companyDoc] = useDocument(
    companyRef, { snapshotListenOptions: { includeMetadataChanges: true } })

  return (
    <Box>
      <Groups companyDoc={companyDoc} companyRef={companyRef}/>
    </Box>
  )
}

function Groups({ companyDoc, companyRef }) {
  const groupsRef = collection(companyRef, 'groups')

  const [groups, loading, error] = useCollection(groupsRef)

  const [group, setGroup] = React.useState(groups?.docs[0]?.id || null)

  return <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={group}
      onChange={(event, newValue) => setGroup(newValue)}
      sx={{ borderRight: 1, borderColor: 'divider' }}
    >
      {groups?.docs.map((doc, i) => {
        if (i === 0 && !group) {
          setGroup(doc.id)
        }
        return <Tab id={doc.id} label={doc.id} key={doc.id} value={doc.id}/>
      })}
    </Tabs>
    {groups?.docs.map((doc) => {
      if (doc.id !== group) {
        return <div key={doc.id}/>
      }
      return <Group groupsRef={groupsRef} value={doc.id} key={doc.id} index={doc.id}/>
    })}
  </Box>
}

const columns = [
  { field: 'account', headerName: friendlyNameTransform.account, flex: 0.3, editable: false },
  { field: 'nemeanAssetId', headerName: friendlyNameTransform.nemeanAssetId, flex: 0.3, editable: false },
  { field: 'clientAssetId', headerName: friendlyNameTransform.clientAssetId, flex: 0.3, editable: false }
]

function Group({ groupsRef, value }) {
  const [rows, setRows] = useState([])
  const groupRef = doc(groupsRef, value)
  const [groupDoc] = useDocument(groupRef)
  const assetsRef = collection(groupRef, 'assets')
  const [assetsCollection] = useCollection(assetsRef)
  const data = groupDoc?.data()

  useEffect(() => {
    if (assetsCollection && assetsCollection.docs && assetsCollection.docs.length) {
      const map = assetsCollection.docs.map(a => {
        const d = a.data()
        return ({
          id: a.id,
          ...d
        })
      })
      console.log('-------------->', map)
      setRows(map)
    }
  }, [assetsCollection])

  return <Box sx={{ p: 3, gap: '1em', display: 'flex', flexDirection: 'column' }}>
    <FormControl style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em' }}>
      <Field style={{ minWidth: '300px', flexGrow: 1 }}
             data={data?.recipients} label="Recipients"/>
      <Field style={{ minWidth: '300px', flexGrow: 1 }}
             data={`${data?.status}${data?.reason ? ' - ' + data?.reason : ''}`} label="Status"/>
    </FormControl>
    <DataGrid
      pagination
      autoPageSize
      pageSize={10}
      autoHeight={true}
      columns={columns}
      rows={rows}/>
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
      <Button href={`/api/pdf?group=${encodeURIComponent(groupRef.path)}`}>View PDF</Button>
    </Box>
  </Box>

}
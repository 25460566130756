import { Box, Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { Fragment } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { Settings } from './Settings'
import { Users } from './Users'

export function Admin() { // eslint-disable-line react/display-name
  const location = useLocation()
  const [, , tab] = location.pathname.split('/')

  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <Tabs value={tab} className="TopMenu" variant="scrollable" scrollButtons="auto">
          <Tab label="Settings" value="settings" to="settings" component={Link}/>
          <Tab label="Users" value="users" to="users" component={Link}/>
        </Tabs>
      </Box>
      <Routes>
        <Route path="/" element={<Settings/>}/>
        <Route path="settings" element={<Settings/>}/>
        <Route path="users" element={<Users/>}/>
      </Routes>
    </Fragment>
  )
}

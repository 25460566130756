import { Box, Button } from '@mui/material'
import * as React from 'react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Pane } from './ReactUtils'
import { styled } from '@mui/material/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { DataGrid } from '@mui/x-data-grid'
import { collection, doc, limit, onSnapshot, orderBy, query } from 'firebase/firestore'
import { BACKEND, firestore } from './firebase'
import { useCollection } from 'react-firebase-hooks/firestore'
import Typography from '@mui/material/Typography'
import { WhenReady } from './AccessDenied'
import Link from '@mui/material/Link'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

const columns = [
  { field: 'timestamp', headerName: 'Timestamp', flex: 0.3, editable: false },
  { field: 'by', headerName: 'By', flex: 0.3, editable: false },
  {
    field: 'file',
    headerName: 'File',
    flex: 1,
    editable: false,
    renderCell: params => {
      return (<Fragment>
        <Link href={`upload-csv/${params.id}`}>{params.value}</Link>


        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  size="small"*/}
        {/*  style={{ marginLeft: 16 }}*/}
        {/*  tabIndex={params.hasFocus ? 0 : -1}*/}
        {/*>*/}
        {/*  Open*/}
        {/*</Button>*/}
      </Fragment>)
    }
  },
  { field: 'status', headerName: 'Status', flex: 0.3, editable: false }
]

export function UploadCSV() {
  const fileRef = React.createRef()
  const [rows, setRows] = useState([])
  const [status, setStatus] = useState('')
  const formRef = useRef()

  let q = collection(firestore, 'imports')
  q = query(q, limit(100))
  q = query(q, orderBy('timestamp', 'desc'))

  const [data, loading, error] = useCollection(q)

  useEffect(() => {
    if (data && data.docs && data.docs.length) {
      let map = data.docs.map(a => {
        let d = a.data()
        return ({
          id: a.id,
          timestamp: d.timestamp.toDate().toLocaleString(),
          by: d.by,
          file: d.filename,
          status: d.status + (d.done && d.progress ? '' : ` (${d.progress})`)
        })
      })
      setRows(map)
    }
  }, [data])

  return (
    <Fragment>
      <WhenReady loading={loading} error={error} data={data}>
        <Pane>
          <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '15px' }}>
            <Box style={{ display: 'flex', gap: '15px', alignItems: 'center', flexWrap: 'wrap' }}>
              <form ref={formRef} method="post" action={`${BACKEND}/upload`}
                    encType="multipart/form-data" id="uploadForm">
                <Button
                  component="label"
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon/>}
                  onChange={handleUpload}
                >
                  Upload File
                  <VisuallyHiddenInput type="file" ref={fileRef}/>
                </Button>
              </form>
              <Typography>{status}</Typography>
            </Box>
            <DataGrid
              autoHeight={true}
              columns={columns}
              rows={rows}/>
          </Box>
        </Pane>
      </WhenReady>
    </Fragment>
  )

  async function uploadFileAsPost(file, fileName) {
    const formData = new FormData()
    formData.append('file', file, fileName)
    const response = await fetch(`${BACKEND}/upload`, {
      method: 'POST',
      body: formData
      // mode: 'no-cors'
    })
    const { ok, reason, pathOfImportJob } = await response.json()
    if (!ok) {
      throw new Error(`Failed to upload file: ${reason}`)
    }
    return pathOfImportJob
  }

  async function handleUpload() {
    const current = fileRef.current
    for (const file of current.files) {
      setStatus(`Uploading file ${file.name}...`)

      let fileName = `${file.name}`
      let unsubscribe
      try {
        const pathOfImportJob = await uploadFileAsPost(file, fileName)
        console.log('Trying get import job:', pathOfImportJob)

        await new Promise((resolve, reject) => {
          unsubscribe = onSnapshot(doc(firestore, pathOfImportJob), doc => {
            const data = doc.data()
            const { status, done } = data
            if (done) {
              unsubscribe()
              unsubscribe = undefined
              resolve()
              return
            }
            setStatus(status)
          })
        })
      } catch (e) {
        if (unsubscribe) {
          unsubscribe()
          unsubscribe = undefined
        }
        setStatus(`Failed to upload file ${file.name}: ${e.message}`)
        break
      }
    }
    current.value = ''
    setTimeout(() => {
      setStatus('')
    }, 1000)
  }
}

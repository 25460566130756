import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import 'firebase/firestore'
import { getAnalytics, logEvent } from 'firebase/analytics'
import {
  CACHE_SIZE_UNLIMITED,
  doc,
  initializeFirestore,
  getFirestore, connectFirestoreEmulator,
  onSnapshot, serverTimestamp, setLogLevel
} from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as React from 'react'
import { useEffect } from 'react'
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage'

export const BACKEND = '/api'
export const USE_FIRESTORE_EMULATOR = window.location.hostname.includes('localhost')

const production = {
  apiKey: 'AIzaSyC7tvegg9DbI7CyK2pWeSowfRcxQdrByGg',
  authDomain: 'namean-toolbox.firebaseapp.com',
  projectId: 'namean-toolbox',
  storageBucket: 'namean-toolbox.appspot.com',
  messagingSenderId: '925597336782',
  appId: '1:925597336782:web:c898f1eb865b727d1dbc9f'
}

export const firebaseConfig = production

export const firebaseApp = initializeApp(firebaseConfig)

export const auth = getAuth(firebaseApp)

export const db = getDatabase(firebaseApp)

function resolveFirestore() {
  if (USE_FIRESTORE_EMULATOR) {
    console.log('Using Firestore emulator')
    const firestore = getFirestore()
    connectFirestoreEmulator(firestore, 'localhost', 8080)
    return firestore
  }
  return initializeFirestore(firebaseApp, {
    ignoreUndefinedProperties: true,
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  })
}

export const firestore = resolveFirestore()

const storage = getStorage()
const storageRef = ref(storage)

export async function uploadFile(file, newFileName) {
  // Push to child path.
  const snapshot = await uploadBytes(ref(storageRef, 'files/' + (newFileName || file.name)), file)
  const metadata = snapshot.metadata
  console.log('Uploaded', metadata.size, 'bytes.')
  console.log('File metadata:', metadata)
  const url = await getDownloadURL(snapshot.ref)
  console.log('File available at', url)
  return { url, metadata }
}

export function getCreatedData() {
  return {
    created: {
      by: auth.currentUser.email,
      timestamp: serverTimestamp()
    }
  }
}

// enableIndexedDbPersistence(firestore)
//   .catch((err) => {
//     if (err.code === 'failed-precondition') {
//       console.error('Multiple tabs open, persistence can only be enabled in one tab at a a time.')
//     } else if (err.code === 'unimplemented') {
//       console.error('The current browser does not support all of the features required to enable persistence.')
//     }
//   })

const provider = new GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

export const analytics = getAnalytics()

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider).then(() => logEvent(analytics, 'logged in'))
}

let userDetailsHandler

export function useUserDetails() {
  const [user, loading, error] = useAuthState(auth)
  const [result, setResult] = React.useState([null, true, false])

  useEffect(async () => {
    if (userDetailsHandler) {
      if (result[0] !== userDetailsHandler) {
        setResult([userDetailsHandler, false, false])
      }
      return
    }
    if (error) {
      setResult([null, false, error])
      return
    }
    if (loading) {
      setResult([null, loading, false])
      return
    }
    if (!user) {
      return
    }
    const { email } = auth.currentUser

    onSnapshot(doc(firestore, 'users', email), userDoc => {
      const userData = userDoc.data()
      const { accessRights, dashboard = '/', admin } = userData

      console.log('----------------> useUserDetails:', email, userData)

      function canAccess(right) {
        if (admin) {
          return true
        }
        if (!accessRights) {
          // TODO: user groups
          return true
        }
        return accessRights.includes(right)
      }

      userDetailsHandler = {
        can: right => {
          let r = canAccess(right)
          if (!r) {
            console.log('access denied to', right)
          }
          return r
        }, dashboard, admin
      }

      setResult([userDetailsHandler, false, false])

    })
  }, [user, loading, error])

  return result
}

import Button from '@mui/material/Button'
import { signInWithGoogle } from './firebase'
import { Login as LoginIcon } from '@mui/icons-material'

export function Login() {
  return <div className={'singleMessagePanel'}>
    v0.0.1
    <Button variant="contained" onClick={signInWithGoogle} style={{ gap: '10px' }}><LoginIcon/>Sign in with
      Google</Button>
  </div>
}

import * as React from 'react'
import { Typography } from '@mui/material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import Title from './Title'
import { useCollection } from 'react-firebase-hooks/firestore'
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc
} from 'firebase/firestore'
import { firebaseApp, firestore } from './firebase'
import { PersonAdd } from '@mui/icons-material'
import { WhenReady } from './AccessDenied'
import { ButtonWithConfirmation, Pane, WhenNotEmpty } from './ReactUtils'

export function UnknownUsers() {
  const [value, loading, error] = useCollection(
    query(collection(getFirestore(firebaseApp), 'unknownUsers'), orderBy('time', 'desc'), limit(10)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  return <WhenReady loading={loading} error={error}>
    <Pane>
      <Title>Recently Denied Access</Title>
      <Typography variant={'caption'}>These users tried to login into the system but their access was
        denied.</Typography>
      <WhenNotEmpty data={value}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Last Connection</TableCell>
              <TableCell>Number of Tries</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value && value.docs.map((user) => {
              const row = user.data()
              let email = user.id
              return (
                <TableRow key={email}>
                  <TableCell>{email}</TableCell>
                  <TableCell>{row.time.toDate().toISOString()}</TableCell>
                  <TableCell>{row.count}</TableCell>
                  <TableCell>
                    <ButtonWithConfirmation
                      tooltip={'Authorize user'}
                      title="Confirm user authorization?"
                      content={
                        <span>By confirming this you'll be giving <b>{email}</b> access to this system.</span>}
                      onConfirm={async () => {
                        await deleteDoc(doc(firestore, 'unknownUsers', email))
                        await setDoc(doc(firestore, 'users', email), { started: serverTimestamp() })
                      }}>
                      <PersonAdd/>
                    </ButtonWithConfirmation>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </WhenNotEmpty>
    </Pane>
  </WhenReady>
}



export const fieldNameTransform = {
  'Nemean Asset Nos': 'nemeanAssetId',
  'Company/Individual': 'company',
  'Client Asset Nos': 'clientAssetId',
  Account: 'account',
  'Audit Confirmation - Recipients ': 'recipients',
  Group: 'group'
}

export const friendlyNameTransform = Object.fromEntries(
  Object.entries(fieldNameTransform).map(([key, value]) => [value, key]))

export function translateToFieldName(data) {
  return Object.fromEntries(Object.entries(data).map(([key, value]) => {
    const newKey = fieldNameTransform[key] || key
    return [newKey, value]
  }))
}

export function translateToFriendlyName(data) {
  return Object.fromEntries(Object.entries(data).map(([key, value]) => {
    const newKey = friendlyNameTransform[key] || key
    return [newKey, value]
  }))
}
